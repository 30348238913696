<template>
	<div>
		<!-- 头部logo -->
		<headLogo @navigation="navigation"></headLogo>
		
		<!-- 头部导航 -->
		<navigation :headTitle="headTitle" :headImg="headImg" style="margin-top:96px;"></navigation>
		
		<!-- 一级切换 -->
		<div class="programsOnePC">
			<el-row class="programsOnePC-box">
				<el-col :span="12" :class="[this.value==1?'highlightsProgPC-yes':'highlightsProgPc-no']">
					<div @click="seasonal">
						HIGHLIGHTS
					</div>
				</el-col>
				<el-col :span="12" :class="[this.value==2?'performancesProgPC-yes':'performancesProgPc-no']">
					<div @click="performances">
						PERFORMANCES
					</div>
				</el-col>
			</el-row>
			<!-- 一级切换数据 -->
			<el-row id="seaData">	
				<!-- 二级数据 -->
				<el-row v-if="notSeaData">
					<el-row>
						<el-col :span="12" v-for="(item,id) in seaListData" :key="id">
							<div class="newsTwo-data" @click="details(item)">
								<el-row class="newsTwo-data-extend">
									<img :src="eventsNotLoadImage" :onload="onLoadImg(item.pictureUrl)" class="newsTwodata-img"></img>
									<el-image class="newsTwodata-coverageimg" src="/home/newCoverage.png"></el-image>
									<p class="newsTwodata-logoimg"></p>
									<div class="newsTwodata-TitleMessage">
										<p class="newsTwodata-title">{{item.title}}</p>
										<p class="newsTwodata-message" v-html="item.bodyContent">{{item.bodyContent}}</p>
									</div>
									<div class="newsTwodata-date">
										<el-image class="newsTwodata-date-chainimg" src="/home/chain.png"></el-image>
									</div>
								</el-row>
							</div>
						</el-col>
					</el-row>
					<!-- 按钮 -->
					<el-row>
						<button v-if="sealoading" class="productionsProgPc-btn" @click="seaMore">Load More</button>
					</el-row>
				</el-row>
				<el-row v-else>
					<el-row>
						<el-image src="/index/nodata.png" class="error-img"></el-image>
					</el-row>
					<el-row class="error-message">
						Empty data
					</el-row>
				</el-row>
			</el-row>
			<!-- 三级数据 -->
			<el-row id="perData">	
				<el-row class="productions-concertbox">
					<el-row>
						<el-col :span="3" :class="[this.productionscss==1?'productions-concertyes':'productions-concert']">
							<div @click="concert">
								CONCERT
							</div>
						</el-col>
						<el-col :span="3" :class="[this.productionscss==2?'productions-concertyes':'productions-concert']">
							<div @click="theatre">
								THEATRE
							</div>
						</el-col>
						<el-col :span="5" :class="[this.productionscss==3?'productions-concertyes':'productions-concert']">
							<div @click="ballet">
								BALLET AND DANCE
							</div>
						</el-col>
						<el-col :span="3" :class="[this.productionscss==4?'productions-concertyes':'productions-concert']">
							<div @click="musical">
								MUSICAL
							</div>
						</el-col>
						<el-col :span="10" :class="[this.productionscss==5?'productions-concertyes':'productions-concert']">
							<div @click="operaTraditional">
								OPERA AND CHINESE TRADITIONAL OPERA
							</div>
						</el-col>
					</el-row>
					
					<!-- 二级切换数据 -->
					<el-row>
						<el-row v-if="notPerData">
							<el-row>
								<el-col :span="8" v-for="(item,id) in perListData" :key="id" class="newsPublicityPc-dataall">
									<div class="news-data" @click="details(item)">
										<el-row class="news-data-extend">
											<img :src="newsNotLoadImage" :onload="onLoadImg(item.pictureUrl)" class="newsdata-img"></img>
											<el-image class="newsdata-coverageimg" src="/home/newCoverage.png"></el-image>
											<p class="newsdata-threeLogoimg"></p>
											<div class="newsdata-titleMessage">
												<p class="newsdata-title">{{item.title}}</p>
												<p class="newsdata-date">
													<span>{{item.month}}</span>
													<span>{{item.time}},</span>
													<span>{{item.year}}</span>
												</p>
											</div>
										</el-row>
									</div>
								</el-col>
							</el-row>
							<el-row>
								<button v-if="perloading" class="productionsProgPc-btn" @click="perMore">Load More</button>
							</el-row>
						</el-row>
						<el-row v-else>
							<el-row>
								<el-image src="/index/nodata.png" class="error-img"></el-image>
							</el-row>
							<el-row class="error-message">
								Empty data
							</el-row>
						</el-row>
					</el-row>
				</el-row>
			</el-row>
		</div>
		
		<!-- 菜单导航 -->
		<meauDialog :meauValue="navigationvalue" @meauFn="menu"></meauDialog>
		
		<!-- 底部导航 -->
		<bottomNavigation></bottomNavigation>
	</div>
</template>

<script>
	// 图片查询接口
	import { selectPictureListById , selectContentListById } from '@/api/index.js'

	// 头部组件
	import headLogo from '@/components/headLogo.vue'
	// 头部导航
	import navigation from '@/components/navigation.vue'
	// 底部导航
	import bottomNavigation from '@/components/bottomNavigation.vue'
	// 菜单导航
	import meauDialog from '@/components/meauDialog.vue'
	export default
	{
		data()
		{
			return{
				// 头部导航标题
				headTitle:'PROGRAMS',
				// 头部导航图片
				headImg:'/index/programs.png',
				// 导航值
				navigationvalue:false,
				
				index:false,
				
				// 一级数据显示条数
				// 加载按钮
				higloading:true,
				// 二级数据显示条数
				seaCount:4,
				// 加载按钮
				sealoading:true,
				// 三级数据显示条数
				perCount:9,
				// 加载按钮
				perloading:true,
				
				value:1,
				introduce:[],
				newList:[],
				perList:[],
				productionsvalue:1,
				productionscss:1,
				
				moduleType:'MODULE_CONCERT',
				
				// 高度没有数据
				notData:true,
				// 第二部分
				notSeaData:true,
				// 第三部分
				notPerData:true,
				
				newsNotLoadImage: "/PCimage/370_424.jpg", //新闻图片未加载出来
				higNotLoadImage: "/PCimage/560_315.jpg", //hig图片未加载出来
				eventsNotLoadImage: "/PCimage/570_428.jpg", //事件图片未加载出来
			}
		},
		components:
		{
			headLogo,
			navigation,
			bottomNavigation,
			meauDialog
		},
		computed:
		{
			// 第二部分
			seaListData()
			{
				let data=[];
				if(this.newList.length>4)
				{
					data=this.newList.slice(0,this.seaCount)
					return data;
				}
				else
				{
					data=this.newList;
					this.sealoading=false;
					return data;
				}
			},
			
			// 第三部分
			perListData()
			{
				let data=[];
				if(this.perList.length>9)
				{
					data=this.perList.slice(0,this.perCount)
					return data;
				}
				else
				{
					data=this.perList;
					this.perloading=false;
					return data;
				}
			}
		},
	
		created()
		{
			this.getSeaList();
			this.getPerList();
		},
		
		mounted()
		{
			window.addEventListener('scroll', this.scrollEvent);
		},
		// deactivated()
		// {
		// 	window.removeEventListener('scroll', this.scrollEvent);
		// },
		methods:
		{
			// 头部传来的数据
			navigation(v)
			{
				this.navigationvalue=v;
			},
			// 遮罩层传回来的数据
			menu(v)
			{
				this.navigationvalue=v;
			},
			
			// sea数据
			getSeaList()
			{
				selectContentListById(
				{
				   chnanelType: 1,
				   isIndex: 0,
				   moduleType: 'MODULE_SEASONAL'
				})
				.then((response)=>
				{
					if(response.data.data=='')
					{
						this.notSeaData=false;
					}
					else
					{
						this.notSeaData=true;
						this.sealoading=true;
						this.newList=response.data.data;
					}
				})
			},
			// per数据
			getPerList()
			{
				selectContentListById(
				{
				   chnanelType: 1,
				   isIndex: 0,
				   moduleType: this.moduleType
				})
				.then((response)=>
				{
					if(response.data.data=='')
					{
						this.notPerData=false;
					}
					else
					{
						this.notPerData=true;
						this.perloading=true;
						this.perList=response.data.data;
						this.perList.map((v)=>
						{
							this.time(v)
						})
					}
				})
			},
			
			time(v)
			{
				v.pulish=v.publish.split('-')
				if(v.pulish[1]=='01')
				{
					v.pulish[1]='JAN'
				}
				else if(v.pulish[1]=='02')
				{
					v.pulish[1]='FEB'
				}
				else if(v.pulish[1]=='03')
				{
					v.pulish[1]='MAR'
				}
				else if(v.pulish[1]=='04')
				{
					v.pulish[1]='APR'
				}
				else if(v.pulish[1]=='05')
				{
					v.pulish[1]='MAY'
				}
				else if(v.pulish[1]=='06')
				{
					v.pulish[1]='JUN'
				}
				else if(v.pulish[1]=='07')
				{
					v.pulish[1]='JUL'
				}
				else if(v.pulish[1]=='08')
				{
					v.pulish[1]='AUG'
				}
				else if(v.pulish[1]=='09')
				{
					v.pulish[1]='SEP'
				}
				else if(v.pulish[1]=='10')
				{
					v.pulish[1]='OCT'
				}
				else if(v.pulish[1]=='11')
				{
					v.pulish[1]='NOV'
				}
				else if(v.pulish[1]=='12')
				{
					v.pulish[1]='DEC'
				}
				v.year=v.pulish[0];
				v.month=v.pulish[1];
				v.time=v.pulish[2];
			},
		
			seasonal()
			{
				this.value=1;
				var PageTd=document.querySelector('#seaData')
				window.scrollTo(
				{
					'top':PageTd.offsetTop,
					'behavior':'smooth'
				})
			},
			performances()
			{
				this.value=2;
				var PageTd=document.querySelector('#perData')
				window.scrollTo(
				{
					'top':PageTd.offsetTop,
					'behavior':'smooth'
				})
			},
			
		
			
			// 第二部分 加载 / 收起
			seaMore()
			{
				this.seaCount+=4;
				if(this.seaCount>=this.newList.length)
				{
					this.sealoading=false
				}
			},
		
			
			// 第三部分 加载 / 收起
			perMore()
			{
				this.perCount+=9;
				if(this.perCount>=this.newList.length)
				{
					this.perloading=false
				}
			},
			
			// 滑动事件
			scrollEvent()
			{
				// 获取视窗高度
				let domHight = document.body.offsetHeight;
				// dom滚动位置
				let scrollTop =
				  window.scrollY ||
				  document.documentElement.scrollTop ||
				  document.body.scrollTop;
				var PageTdSea=document.querySelector('#seaData')
				var PageTdPer=document.querySelector('#perData')
				if(scrollTop>=PageTdSea.offsetTop)
				{
					this.value=1;
				}
				if(scrollTop>=PageTdPer.offsetTop)
				{
					this.value=2;
				}
			},
			
			// sea & per 跳转文章详情
			details(item)
			{
				localStorage.setItem('Details',JSON.stringify(item));
				this.$router.push(
				{
					path:'/noticesDetails',
					query:
					{
						item:item,
						headTitle:'PROGRAMS',
						headImg:'/index/programs.png',
					}
				})
			},
			
			// 音乐会 切换
			concert()
			{
				this.productionsvalue=1;
				this.productionscss=1;
				this.perCount=9;
				this.moduleType='MODULE_CONCERT'
				this.getPerList();
			},
			// 剧院 切换
			theatre()
			{
				this.productionsvalue=1;
				this.productionscss=2;
				this.perCount=9;
				this.moduleType='MODULE_THEATRE'
				this.getPerList();
			},
			// 芭蕾舞剧 切换
			ballet()
			{
				this.productionsvalue=1;
				this.productionscss=3;
				this.perCount=9;
				this.moduleType='MODULE_DANCE'
				this.getPerList();
			},
			// 歌剧院 切换
			musical()
			{
				this.productionsvalue=1;
				this.productionscss=4;
				this.perCount=9;
				this.moduleType='MODULE_MUSICAL'
				this.getPerList();
			},
			// 戏剧 切换
			operaTraditional()
			{
				this.productionsvalue=1;
				this.productionscss=5;
				this.perCount=9;
				this.moduleType='MODULE_OPERA'
				this.getPerList();
			},
			
			// 图片加载完成时触发
			onLoadImg(imgSrc) 
			{
				return 'this.src='+'"'+imgSrc+'";this.οnlοad=null';
			},
		}
	}
</script>

<style scoped>
	/* 二格数据 */
	@import '../assets/TwoDataBox.css';
	/* news数据 */
	@import '../assets/newsBox.css';
	.programsOnePC{
		width: 1170px;
		margin: 0 auto;
		margin-top: 30px;
	}
	.programsOnePC-box{
		width: 80%;
		border: solid 2px #ccc;
		color: #505050;
		margin-left: 10%;
		border-top-left-radius: 50px;
		border-bottom-left-radius: 50px;
		border-top-right-radius: 50px;
		border-bottom-right-radius: 50px;
		line-height: 50px;
		margin-bottom: 50px;
		font-size: 20px;
		color: #505050;
	}
	.highlightsProgPC-yes{
		height: 46px;
		background-color: #FCD002;
		border-top-left-radius: 50px;
		border-bottom-left-radius: 50px;
	}
	.highlightsProgPc-no{
		height: 46px;
		background-color: #F0EDF1;
		border-top-left-radius: 50px;
		border-bottom-left-radius: 50px;
	}
	.performancesProgPC-yes{
		height: 46px;
		background-color: #FCD002;
		border-top-right-radius: 50px;
		border-bottom-right-radius: 50px;
	}
	.performancesProgPc-no{
		height: 46px;
		background-color: #F0EDF1;
		border-top-right-radius: 50px;
		border-bottom-right-radius: 50px;
	}
	.productionsProgPc-btn{
		width: 400px;
		height: 50px;
		border: none;
		border-radius: 50px;
		background-color: #FCD002;
		margin-top: 30px;
		line-height: 25px;
		font-size: 24px;
		color: #505050;
	}
	.productionsProgPc-nobtn{
		width: 30%;
		height: 40px;
		border: none;
		border-radius: 50px;
		font-size: 15px;
		margin-top: 50px;
		background-color: #ccc;
		color: #505050;
	}
	.programsOnePC-introduce{
		width:100%;
		height:350px;
		position:relative; 
		color:#505050;
		margin-top: 20px;
	}
	.programsOnePC-imgbox{ 
		width: 40%;
		position:absolute; 
		border-radius: 20px;
		z-index: 34;
		float: left;
	} 
	.programsOnePC-introduceimg{
		width: 560px;
		height: 315px;
		border-radius: 20px;
		z-index: 34;
	}
	.programsOnePC-introducebox{
		width: 700px; 
		height: 360px;
		position:absolute;
		right:50px;
		top: -25px;
		background-color: #F6F5F8;
		border-radius: 20px;
		padding: 50px 2% 30px 150px;
		text-align: left;
		float: left;
	}
	.programsOnePC-introducetitle{
		height: 90px;
		text-align: left;
		margin-top: 30px;
		line-height: 30px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		font-size: 30px;
		color: #505050;
	}
	.programsOnePC-introducemessage{
		text-align: left;
		font-size: 15px;
		line-height: 20px;
		margin-top: 30px;
		word-wrap : break-word;
		white-space: pre-wrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		height: 75px;
	}
	.programsOnePC-introducemessage p{
		color: #505050;
	}
	.programsOnePC-introducemessage a{
		color: #505050;
	}
	.across-img{
		width: 170px;
		height: 6px;
		position: absolute;
		z-index: 99;
		top: 50px;
		left: 70px;
	}
	.error-img{
		width: 300px;
		height: 200px;
		margin-top: 30px;
	}
	#perData{
		margin-top: 50px;
		padding-top: 30px;
	}
	.productions-concert{
		border-bottom: solid 1px black;
		padding-bottom: 5px;
		height: 40px;
		margin-bottom: 30px;
		font-size: 20px;
		color: #505050;
	}
	.productions-concertyes{
		height: 40px;
		border-bottom: solid 6px #FDD000;
		margin-bottom: 30px;
		font-size: 20px;
		color: #505050;
	}
</style>